import { createRouter, /*createWebHistory,*/ createMemoryHistory } from 'vue-router'
import HomeView from './views/HomeView.vue'
import IconsView from './views/IconsView.vue'
import FeedbackView from './views/FeedbackView.vue'
import SidebarView from './views/SidebarView.vue'

const routes = [
  { path: '', component: HomeView },
  { path: '/icons', component: IconsView },
  { path: '/feedback', component: FeedbackView },
  { path: '/sidebar', component: SidebarView }
]

const router = createRouter({
  /**
   * - memory - виртуальная навигация без
   *   использования `location`
   */
  history: createMemoryHistory(),
  routes
})

export default router
