<script lang="ts" setup>
import { ref } from 'vue'
import * as yup from 'yup'
import { vMaska } from 'maska'
import BulmaInput from './BulmaInput.vue'
import BulmaTextarea from './BulmaTextarea.vue'
import FormLegal from './FormLegal.vue'

/**
 * Для добавления дополнительных или
 * замены существующих проверок можно использовать
 * в цепочке `.test(...)` или добавлять проверки
 * через `yup.addMethod()`. `Validator.js` обладает
 * отличным набором. Например, расширенной проверкой
 * email или мобильных телефонов.
 *
 * @see https://github.com/validatorjs/validator.js/tree/master/src/lib
 */
import isEmail from 'validator/es/lib/isEmail'
// import isMobile from 'validator/es/lib/isMobile'

// yup.addMethod(yup.string, 'customEmail', function () {

// })

/**
 * Тупорылый vee-validate использует yup, который
 * не может проверить нормально почту ... в 2024 году, Карл!
 * А для поддержки zod использует переходник, который трансформирует
 * схему zod в yup. Ну это не идиотизм? Документация VeeValidate 4.х
 * превратилась в бесконечные повторения. Примеры не имеют единой
 * логики и предлагают все время разные варианты использования,
 * порождая хаос. Ну вот так... То что в примерах нет системы
 * говорит об одном, сделано много и принята логика новой и мощной
 * архитектуры Vue 3.x, но получилась просто "каша" и нагромождение
 * функций. Нахрена? Неясно. Ах да, предлагает сделать свой
 * конструктор форм))) Идиоты. Как десять лет назад люди
 * пользовались формами? Без всей этой параши?!
 *
 * Возможно лучшим вариантом будет вообще использование
 * встроенных глобальных правил проверки vee-validate.
 *
 * C zod и VeeValidate есть проблемы. refine/superRefine
 * @see https://github.com/logaretm/vee-validate/issues/4338
 *
 */

/**
 * Требования к полям формы объединяются в цепочки,
 * здесь же можно указать сообщения об ошибках.
 *
 * Очевидно, проверка касается только UI/UX и
 * помогает пользователю не допускать ошибки при заполнении формы.
 * Никакой защиты эта модель не предлагает. Защита от спама,
 * злонамеренных действий и т.д., должна происходить на уровне
 * сервера.
 */
const validationSchema = yup.object({
  email: yup
    .string()
    .default('')
    // Встроенный не понимает национальные символы
    //.email('Необходим адрес электронной почты.')
    .required('Требуется заполнить')
    /**
     * Таким образом можно использовать любые доступные
     * в `validator` проверки. Предварительно импортировав.
     * Или добавить через yup.addMethod() к любой цепочке или глобально.
     */
    .test('validator-email', 'Необходим адрес электронной почты', (value) => {
      return isEmail(value)
    }),
  text: yup
    .string()
    .required('Требуется заполнить.')
    .max(1024, 'Не более 1024 символов.')
    .default(''),
  name: yup
    .string()
    .required('Требуется заполнить.')
    .max(255, 'Не более 255 символов.')
    .default(''),
    phone: yup
      .string()
      // .optional()
      .notRequired() // same as .nullable().optional()
      // .default('')
      .test('mobile-phone', 'Требуется указать мобильный номер российского оператора.', (value) => {
        const _value = String(value).replace(/[\s-]*/g, '')
        return /^(\+?7|8)?9\d{9}$/.test(_value)
      })
})

/**
 * Определение форм возможно с помощью useForm(), импортируемой
 * из основного пакета `vee-validate`. Это функция "composition api",
 * которая отмечает, маркирует используемый компонент как форму. То есть
 * создается весь необходимый контекст для управления полями, данными,
 * ошибками, отправкой формы. Контекст распространяется на все
 * дочерние компоненты. Это означает, что необходимо вызывать `useForm`,
 * всего один раз.
 *
 * Для определения главных элементов формы (Input), возможно
 * использовать `defineField()` из `useForm`, которая генерирует
 * модель данных и атрибуты для привязки к элементу.
 *
 * ```ts
 * const [email, emailAttrs] = defineField('email');
 * ```
 */

import {
  useField,
  useForm,
} from 'vee-validate';

const {
  /**
   * Набор текущих данных формы. Этот объект так же
   * передается в обработчик handleSubmit. Для простых форм
   * нет необходимости в использовании `values` напрямую
   * в форме. Но, если данные одного поля зависят от другого,
   * или есть пошаговое заполнение, или надо отображать результаты
   * до отправки, этот объект поможет.
   *
   * ЭТО ОБЪЕКТ ПРЕДНАЗНАЧЕН ТОЛЬКО
   * ДЛЯ ЧТЕНИЯ ЗНАЧЕНИЙ.
   */
  // values,
  /**
   * Коллекция ошибок.
   */
  errors,
  /**
   * Мета данные формы.
   * meta.value.*. Используются для
   * принятия решений или изменения интерфейса.
   */
  meta: formMeta,
  /**
   * Помощник для обработки события отправки
   * формы `submit`, в качестве аргумента функция,
   * в которую будут переданы значения формы.
   *
   * Удобство использования в том, что вызов
   * происходит только в случае, если форма
   * содержит корректные значения (valid).
   *
   * Если необходимо, то можно обрабатывать и
   * ошибки при отправке формы (на клиенте),
   * ошибки submit, именно события submit. Например,
   * для установки ошибок вручную или вывода списка
   * ошибок и т.п.
   *
   *
   * ```ts
   * // Для действий на клиенте, не связано с работой сервера.
   * function onError({ values, errors, results }) {
   *   // values - текущие значения полей формы.
   *   // errors - карта ошибок, поле -> первое сообщение об ошибке.
   *   // results - детальная карта состояния проверок, все поля и ошибки.
   * }
   *
   * function onSuccess(values) {
   *   // отправка на сервер...
   * }
   *
   * handleSubmit(onSuccess, onError)
   * ```
   *
   *
   * Цикл submit
   *
   * - Before validation
   *  - set all fields meta.touched = true
   *  - isSubmitting = true
   *  - submitCount++
   * - Validation
   *  - form/field meta.pending = true
   *  - async run validations (schema/rules)
   *  - check validation errors
   *    - errors: set form/fields meta/error
   *    - success: pending = false
   * - After validation
   *   - call handleSubmit(<handler>)
   *   - after <handler> finished, isSubmitting = false
   *
   * У VeeValidate есть возможность классической отправки формы,
   * с перезагрузкой страницы, в таком случае надо использовать
   * `submitForm` вместо `handleSubmit`. Это работает только "родного"
   * тега `<form></form>`.
   *
   *```vue
   * <form action="/my/post/route" @submit="submitForm">...</form>
   *```
   */
  handleSubmit,
  /**
   * Устанавливается в true при начале проверки
   * значений при выполнении обработчика submit
   * события формы и сбрасывается при завершении
   * работы обработчика или вызове исключений, ошибках.
   *
   * Может используется для блокировки полей или
   * показа индикатора прогресса отправки.
   *
   * Дополнительно можно использовать submitCount.
   * Подсчет количества выполнения обработчика submit.
   * Например, вывод сообщения или блокировки формы.
   */
  isSubmitting,
  /**
   * Определение полей формы и их параметров.
   * Возвращает пару значений `[model, attrs]`. Которые
   * используются для настройки полей формы:
   *
   * ```vue
   * <input v-model="model" v-bind="attrs">
   * ```
   */
  defineField,
  /**
   * Сброс формы, значений и мета-состояний
   * к начальным значениям. Можно установить
   * параметры сброса для каждого поля. Например,
   * при получении ошибок с сервера. Обычно
   * полный сброс формы происходит после успешной
   * отправки. Для удобства обработчик onSubmit
   * получает вторым аргументом объект FormActions,
   * из которого можно получить ссылку на formReset()
   * и это наиболее удобный путь.
   *
   * ```ts
   * handleSubmit(function (values, {
   *   setFieldValue,
   *   setFieldError,
   *   setErrors,
   *   setValues,
   *   setFieldTouched,
   *   setTouched,
   *   resetForm // <--
   * }) { ... })
   *```

   */
  resetForm
} = useForm({
  validationSchema,
  /**
   * Начальные значения формы, если необходимо.
   *
   * РЕКОМЕНДУЕТСЯ. Всегда использовать установку
   * начальных значений, иначе все поля будут при загрузке
   * иметь значения `undefined`. Лучше указать: `email: ''`.
   *
   * Начальные значения можно указать при определении
   * схемы в yup, добавив в цепочку `.default('<значение>')`.
   * Таким образом все настройки будут более логичны.
   */
  initialValues: {
    email: '',
    text: '',
    name: '',
    phone: undefined
  }
})

/**
 * @todo how custom fields linked with form?
 *
 * Каким образом происходит связь кастомных компонентов
 * с текущим контекстом формы? Понятно, что поименованию.
 *
 * Нужно точно описать механизм связи и использования.
 *
 */
//const [email, emailAttrs] = defineField('email')
// const [text, textAttrs] = defineField('text')
//const [name, nameAttrs] = defineField('name')
const [phone, phoneAttrs] = defineField('phone')

//const { meta: textMeta } = useField('text')
//const { meta: nameMeta } = useField('name')
const { meta: phoneMeta } = useField('phone')

/**
 * Дополнительный признак для
 * отображения уведомлений с скрытия формы.
 * При успешной отправке.
 */
const justSubmitted = ref(false)

/**
 * Статус управления состоянием интерфейса формы.
 * С учетом результатов отправки на сервер.
 *
 * `pristine` - начальное состояние, отображение формы,
 *              все сообщения скрыты.
 * `success`  - форма успешно отправлена, без ошибок. Форма скрыта,
 *              отображение сообщения об успехе. При закрытии сообщения
 *              форма отображается в начальном состоянии.
 * `error`    - форма отправлена, но сервер возвратил ошибку. Форма
 *              отображается на странице без изменений, дополнительно
 *              отображается сообщение об ошибке отправки и инструкциями
 *              для пользователя. При закрытии сообщения об ошибке, статус
 *              формы не меняется.
 */
const uiStatus  = ref<'pristine'|'error'|'success'|undefined>('pristine')

/**
 * После успешной отправки данных. В
 * сообщении показана кнопка, по которой
 * можно сбросить состояние к начальному.
 *
 * В обработчике успешной отправки
 * форма сбрасывается сразу. Здесь
 * это происходит повторно, для исключения
 * побочных эффектов.
 */
const restartForm = function () {
  justSubmitted.value = false
  uiStatus.value = 'pristine'
  resetForm()
}

/**
 * Обработка события отправки формы. Если
 * проверка полей завершается ошибкой, то
 * функция не сработает.
 */
const onSubmit = handleSubmit(async (values, actions) => {
  console.log(values)
  /**
   * send data to server
   *
   * В связке с основным приложением данные
   * можно отправлять через прокси маршрут `/api/...`.
   * Когда разработка ведется через Docker, запускаются
   * все сервисы сразу и прокси через Nginx.
   *
   * При тестировании отдельно надо предусмотреть
   * соответствующую настройку. Например, когда UI
   * работает на локальном хосте без включения Rails и Directus.
   * Но в таком случае разработка требует учитывать доступ
   * не только к API но и к изображениям в облаке.
   */

  try {
    /**
     * @todo useFetch()
     * @todo use Rails Proxy, для отправки данные с подтверждением.
     *       Данные отправляются на маршрут Rails, там проверяются.
     *       Затем через клиента на стороне сервера Directus API,
     *       отправляются авторизованным запросом в Directus. То есть
     *       используя ключ API (персональный токен робота). Таким
     *       образом невозможно отправлять запросы извне не имея
     *       ключа. Что повышает безопасность. И снижает возможность
     *       прямого спама.
     *
     * POST - /api/mail_messages -> Directus
     * POST - /contacts -> Rails -> Directus (!)
     *
     */
    const csrfTokenElement = document.head.querySelector('meta[name=csrf-token]') as HTMLMetaElement
    const csrfToken = `${csrfTokenElement?.content}`
    const response = await fetch('/contacts', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'CSRF-Token': csrfToken // Не работает
      },
      body: JSON.stringify({
        message: values,
        authenticity_token: csrfToken // Работает
      })
    })

    /**
     * Необходимо проверять ответ сервера.
     * Мы оказываемся здесь при любом не ошибочном ответе сервера.
     * Но это не означает успех выполнения. Поэтому необходимо
     * убедиться в ожидаемом статусе и сообщении.
     *
     * 200/201/204 - успех
     * 3xx,4xx - ошибка обработки
     */

    switch (response.status) {
      case 204:
        uiStatus.value = 'success'
        actions.resetForm()
        break;
      case 422:
        uiStatus.value = 'error'
        break;
      case 400:
        uiStatus.value = 'error'
        break;
      default:
        uiStatus.value = 'error'
        break;
    }
  } catch (e) {
    /**
     * @todo Проверка ошибок сервера и установка в форму
     *
     * Формат возвращаемых ошибок должен быть совместим,
     * чтобы выводить их в форме. Или устанавливать вручную
     * для каждого поля. actions.setFieldError('email', e.errors.email...)
     */
    uiStatus.value = 'error'
    console.log(e, uiStatus.value)
    // actions.setErrors(e)
  } finally {
    console.log('submit finally')
  }
})

/**
 * При закрытии уведомления об ошибке,
 * возвращаем глобальный статус в начальное состояние.
 */
const onCloseErrorNotification = function () {
  uiStatus.value = 'pristine'
}

/**
 *
 * Рекомендуемая структура классов.
 *
 * Помогает в тестировании (e2e).
 *
 * .form-feedback
 *   .form-title
 *   .messages
 *     .notification-success
 *     .notification-error
 *   .form
 *     .field.field-[input name]
 *        .label.label-[input name]
 *        .control.control-[input name]
 *          .input
 *        .help.help-[input name]
 *     .buttons?
 *       .button.button-submit
 *
 */

</script>
<template>
  <div class="form-feedback p-8">
    <div class="messages">
      <o-notification
        v-if="uiStatus === 'success'"
        variant="success"
        class="notification-success"
      >
        <p class="text-xl">
          Ваше сообщение отправлено.
        </p>
        <p class="help py-2">
          Благодарим Вас. Если вы указали
          правильные контактные данные, постараемся ответить
          как можно быстрее.
        </p>

        <div class="controls mt-2">
          <a href="/" class="button mr-2 is-small">
            <span class="icon">
              <i class="i-fa6-solid-house"></i>
            </span>
            <span>
              На главную
            </span>
          </a>

          <o-button @click="restartForm" size="small">
            Закрыть
          </o-button>
        </div>
      </o-notification>

      <div class="ui-status-error" v-if="uiStatus === 'error'">
        <o-notification
          variant="danger"
          @close="onCloseErrorNotification"
          closable
          class="notification-error"
        >
          Ошибка! Не удалось отправить сообщение. Попробуйте снова.
          Если ошибка повторяется перезагрузите страницу.
        </o-notification>
      </div>
    </div>

    <form
      v-if="uiStatus !== 'success'"
      class="form"
      @submit.prevent="onSubmit"
    >

      <p class="mb-5 help">
        Вы можете отправить сообщение через форму или напрямую
        на контактные адреса электронной почты. А также
        воспользоваться телефоном. Как вам удобно. На странице
        посадочного материала так же есть свой
        <a href="/seedlings" target="_blank">контактный номер</a>.
      </p>

      <BulmaInput
        name="name"
        label="Ваше имя *"
        help="Укажите ваши имя и фамилию."
        :submitted="isSubmitting"
        :icon-right-show="true"
        :icon-left-show="true"
      />

      <BulmaInput
        name="email"
        type="text"
        label="Email *"
        icon-left="i-fa6-solid:envelope"
        help="Адрес вашей электронной почты."
        :submitted="isSubmitting"
        :use-mask="true"
        :icon-right-show="true"
        :icon-left-show="true"
      />

      <div class="field field-phone">
        <label for="phone" class="label label-phone">
          Телефон <sup>*</sup>
        </label>

        <p
          class="
            control
            control-phone
            has-icons-left
            has-icons-right
          ">
          <input
            type="text"
            name="phone"
            class="input input-phone"
            :class="{
              'is-success has-background-success-light': phoneMeta.valid && phoneMeta.dirty,
              'is-danger has-background-danger-light': !phoneMeta.valid && phoneMeta.dirty
            }"
            v-bind="phoneAttrs"
            v-model="phone"
            v-maska
            data-maska="+7 ### ###-##-##"
          >

          <span
            class="icon is-left"
            :class="{
              'has-text-danger': phoneMeta.dirty && !phoneMeta.valid,
              'has-text-success': phoneMeta.dirty && phoneMeta.valid
            }"
          >
            <i class="i-fa6-solid-mobile" />
          </span>

          <span
            class="icon is-right"
            :class="{
              'has-text-danger': phoneMeta.dirty && !phoneMeta.valid,
              'has-text-success': phoneMeta.dirty && phoneMeta.valid
            }"
          >
            <i
              class="i-fa6-solid-check"
              v-show="phoneMeta.valid"
            />
            <i
              class="i-fa6-solid:circle-exclamation"
              v-show="!phoneMeta.valid"
            />
          </span>
        </p>

        <p class="help">
          Укажите ваш контактный телефон. Пожалуйста введите
          номер мобильного телефона российского оператора.
          Например: 9030000000.
        </p>

        <p class="help help-phone has-text-danger">
          {{ errors.phone }}
        </p>
      </div>

      <div class="field">
        <div class="control has-icons-left">
          <div class="select">
            <select name="subject" class="select" disabled>
              <option value="1">Общие вопросы</option>
              <option value="2">Посадочный материал</option>
              <option value="3">Литература</option>
            </select>
          </div>
          <span class="icon is-left">
            <i class="i-fa6-solid-compass" />
          </span>
        </div>
      </div>

      <BulmaTextarea
        name="text"
        label="Сообщение *"
        help="
          Ваше сообщение, вопрос, предложение или информация.
          Не более 1024 символов.
        "
      />

      <FormLegal />

      <div class="buttons">
        <button
         class="button button-submit is-primary"
         :disabled="!formMeta.valid || isSubmitting"
         type="submit"
       >
         {{ isSubmitting ? 'Отправляю...' : 'Отправить' }}
       </button>
      </div>
    </form>
  </div>
</template>
