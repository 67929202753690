<script setup lang="ts">
import { ref } from 'vue'
const isOpened = ref(false)

function sidePanelToggle () {
  isOpened.value = !isOpened.value
}
</script>
<template>
  <div>
    <p>sidebar demo</p>
    <button class="button" @click.prevent="sidePanelToggle">
      toggle
    </button>
    <VueSidePanel
      v-model="isOpened"
      lock-scroll
      width="600px"
    >
		 <div style="padding-top: 70px; color: #f14668">
        <h2
          v-for="item in 50"
          :key="item"
          :style="{fontSize: '58px', fontWeight: 700, opacity: item * 2 / 100, lineHeight: '43px'}"
        >
          This is scrolled body!
        </h2>
      </div>
    </VueSidePanel>
  </div>
</template>
