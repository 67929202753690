<template>
  <section class="section py-2 bg-blue">
    <div class="container">
      <ul class="navbar">
        <li class="navbar-brand">
          <a class="navbar-item">
            VueAppLinks
          </a>
        </li>
        <li class="navbar-item">
          <RouterLink to="/">Home</RouterLink>
        </li>
        <li class="navbar-item">
          <RouterLink to="/icons">icons</RouterLink>
        </li>
        <li class="navbar-item">
          <RouterLink to="/feedback">Feedback</RouterLink>
        </li>
        <li class="navbar-item">
          <RouterLink to="/sidebar">Sidebar</RouterLink>
        </li>
      </ul>
    </div>
  </section>
</template>
