<script setup lang="ts">
// import { ref } from 'vue'
// import Autocomplete from '../components/Autocomplete.vue'
import { history } from 'instantsearch.js/es/lib/routers'
import { simple } from 'instantsearch.js/es/lib/stateMappings'
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"
import 'instantsearch.css/themes/algolia-min.css'

let nodes = []

/**
 * Требуется настройки обратного прокси на стороне сервера.
 * @todo set api key header on server - X-TYPESENSE-API-KEY
 */

if (process.env.NODE_ENV === 'production') {
  nodes.push({
    host: 'vniispk.ru',
    path: "/__typesense/", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
    port: 443,
    protocol: "https",
  })
} else {
  nodes.push({
    host: 'vniispk-directus.local',
    path: "/__typesense/", // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
    port: 80,
    protocol: "http",
  })
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    /**
     * @note Ключ перемещен в прокси конфигурацию сервера Nginx.
     *
     * Ключ сервера будет установлен при направлении
     * на сервер Typesense. В запросах и ответах (headers)
     * ключа не видно.
     *
     * Дополнительно можно отключить CORS. Так как при использовании
     * прокси запросы передаются на локальный маршрут.
     *
     */
    apiKey: "", // Be sure to use an API key that only allows search operations
    /**
     * По умолчанию ключ добавляется как get-параметр.
     */
    sendApiKeyAsQueryParam: false,
    nodes,
    cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    /**
     * Дополнительно можно включить
     *
     * article - артикул
     */
    query_by: "name,text",
  }
});

const searchClient = typesenseInstantsearchAdapter.searchClient

/**
 * Состояние интерфейса поиска будет отражаться
 * параметрами в адресе страницы. То есть, возможно передать,
 * перейти по ссылке и восстановить состояние запроса.
 *
 */
 const routing = {
  router: history({
    cleanUrlOnDispose: false
  }),
  stateMapping: simple()
}

async function createIndex () {
  console.log('create')

  const csrfTokenElement = document.head.querySelector('meta[name=csrf-token]') as HTMLMetaElement
  const csrfToken = `${csrfTokenElement?.content}`

  try {
    const response = await fetch('/typesense/create', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'CSRF-Token': csrfToken // Не работает
      },
      body: JSON.stringify({
        message: [],
        authenticity_token: csrfToken // Работает
      })
    })

    console.log(response)
  } catch(e) {
    console.warn(e)
  }
}

function showIndex () {
  console.log('show')
}

function  toValue(value: {min:number, max:number}, range: {min:number, max:number}) {
  return [
    typeof value.min === "number" ? value.min : range.min,
    typeof value.max === "number" ? value.max : range.max,
  ];
}
</script>

<template>
  <div class="typesense-ui-index">
    <p>
      Приложение для создания индекса каталога
      на сервере Typesense (только тест). При создании
      индекса данные полностью удаляются и
      воссоздаются вновь. Так легче тестировать функции
      приложения и сервера. Индекс называется varieties.
      Демо-страница поиска /typesense/search.
    </p>

    <p>
      Для работы с серверов используются официальные
      клиенты Typesense.
    </p>

    <div class="buttons mt-4">
      <button class="button" @click="createIndex" disabled>create index</button>
      <button class="button" @click="showIndex">show schema</button>
    </div>

    <div class="typesense-varieties">
      <ais-instant-search
        :search-client="searchClient"
        :routing="routing"
        :future="{
            preserveSharedStateOnUnmount: true,
            persistHierarchicalRootCount: true
        }"
        index-name="varieties"
      >

        <ais-configure
            :hitsPerPage="12"
            :attributesToSnippet="['text']"
            :max_facet_values="9999"
        />

        <div class="columns">
          <!-- filters  -->

          <div class="column column--sidebar is-narrow">
            <ais-dynamic-widgets>

            </ais-dynamic-widgets>

            <ais-clear-refinements
              style="font-family: monospace;"
              :class-names="{
                'ais-ClearRefinements-button': 'button is-small is-info my-2'
              }"
            >
              <template v-slot:resetLabel>
                Сбросить фильтры
              </template>
            </ais-clear-refinements>

            <ais-panel>
              <template v-slot:header>
                Вид
              </template>

              <ais-refinement-list
                attribute="species"
                :limit="20"
              />
            </ais-panel>


            <!--
              @todo migrate to oruga
            -->

            <ais-panel class="my-4">
              <template v-slot:header>
                Госреестр
                <span
                    class="
                      icon
                      i-tabler-rosette-discount-check-filled
                      color-blue
                    "
                    style="width: 16px; height: 16px;"
                  />
              </template>

              <!-- vuetify -->
              <div
                class="range my-4 p-4 b-blue b-solid b-s"
                style="
                  border-radius: .5rem;
                "
              >
                <ais-range-input attribute="registry">
                  <template v-slot="{ currentRefinement, range, refine }">
                    <v-range-slider
                      :disabled="range.min == range.max"
                      :min="range.min"
                      :max="range.max"
                      :model-value="toValue(currentRefinement, range)"
                      @end="refine({
                        min: $event[0],
                        max: $event[1]
                      })"
                      step="1"
                      thumb-label="always"
                    />
                  </template>
                </ais-range-input>
                <p class="help p-0 m-0" style="max-width:200px;">
                  Только для сортов у которых
                  указана дата, <strong>год добавления
                  в государственный реестр</strong>.
                </p>
              </div>
            </ais-panel>


            <ais-panel>
              <template v-slot:header>
                Регион районирования
              </template>

              <ais-refinement-list
                attribute="region"
                :limit="20"
              />
            </ais-panel>
          </div>

          <!-- hits -->

          <div class="column column--hits">
            <ais-search-box
              class="mb-1"
              submit-title="Найти"
              reset-title="Очистить"
              placeholder="Поиск в названиях и описаниях сортов"
              :autofocus="true"
              :show-loading-indicator="true"
              :class-names="{
                'ais-SearchBox-input': 'input',
                'ais-SearchBox-submitIcon': 'i-tabler-star-'
              }"
            >
              <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                  <input
                    type="search"
                    class="input"
                    placeholder="Поиск в названиях и описаниях сортов"
                    :value="currentRefinement"
                    @input="refine($event.currentTarget?.value)"
                  >
                  <span :hidden="!isSearchStalled">loading</span>
                </template>
            </ais-search-box>

            <p class="help mt-0">
              Поиск в названиях и описаниях сортов.
            </p>

            <div>
              <ais-current-refinements />
            </div>

            <div class="my-2">
              <ais-stats />
            </div>

            <ais-sort-by
              class="is-hidden-"
              :items="[
                { value: 'varieties/sort/name:asc', label: 'Название (А-Я)' },
                { value: 'varieties/sort/name:desc', label: 'Название (Я-А)' },
              ]"
            />

            <ais-hits>
              <template v-slot="{ items }">
                <div class="columns is-multiline">
                  <div
                    class="column column is-12-mobile is-6-tablet is-4-desktop"
                    v-for="item in items" :key="item.id"
                  >
                    <div class="card">
                      <div class="card-image">
                        <figure
                          class="image"
                          :style="{
                            height: '180px',
                            backgroundImage: `url(${ (item.preview) ? '/assets/directus/' + item.preview : '/static/128x128.png' })`,
                            backgroundSize: 'cover',
                            backgroundPosition: '50%',
                            position: 'relative'
                          }"
                        >
                          <span
                            v-if="item.registry"
                            class="tag is-small px-1"
                            style="
                              background-color: #fff;
                              position: absolute;
                              right: 1rem;
                              top: 1rem;
                              margin: -0.5rem
                            "
                          >
                            <span
                              class="
                                icon
                                m-0
                                i-tabler-rosette-discount-check-filled
                                color-blue
                              "
                            />
                            <span class="is-hidden">
                              {{ item.registry }}
                            </span>
                          </span>

                        </figure>
                      </div>
                      <div class="card-content">
                        <p class="text mb-4">
                          <span class="columns is-mobile is-vcentered">
                            <span class="column is-narrow pr-0">
                              <span class="icon is-16x16">
                                <img :src="`/assets/directus/${item.icon}`">
                              </span>
                            </span>
                            <span class="column">
                              <ais-highlight
                                attribute="name"
                                :hit="item"
                              />
                            </span>
                          </span>
                        </p>

                        <p
                          class="help"
                          v-html="String(item.text).slice(0, 128) + '...'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </ais-hits>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <ais-pagination />
          </div>
          <div class="column is-narrow">
            <ais-hits-per-page
              :items="[
                { label: '12 на страницу', value: 12, default: true },
                { label: '24 на страницу', value: 24 },
                { label: '48 на странице', value: 48 },
              ]"
            />
          </div>
        </div>

      </ais-instant-search>
    </div>
  </div>
</template>
