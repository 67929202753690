import 'vite/modulepreload-polyfill'

import InstantSearch from 'vue-instantsearch/vue3/es'

import { createApp } from 'vue'
import router from './router'
import App from './App.vue'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives
})

/**
 * oruga don't support Vue 3.4 (!)
 * @todo после ошибки, Vue выпустил >5 патчей, возможно проблема во Vue, а не Oruga
 */

import Oruga from '@oruga-ui/oruga-next'

/**
 * @todo use only required
 *
 * createApp()
 *  .use(Sidebar)
 *  ...
 *
 */
// import {
//   Sidebar,
//   Button,
//   Modal,
//   ...
// } from '@oruga-ui/oruga-next'
/**
 * or
 *
 * createApp()
 *  .component(OSidebar)
 *
 */
// import {
//   OSidebar,
//   OButton,
//   OModal,
//   ...
// } from '@oruga-ui/oruga-next'

import { bulmaConfig } from '@oruga-ui/theme-bulma'
import FeedbackForm from './components/FeedbackForm.vue'
import VueSidePanel from 'vue3-side-panel'

import TypesenseView from './views/TypesenseView.vue'

// unocss
import 'virtual:uno.css'

// all styles: bulma, oruga
import './styles/main.scss'
/**
 * unocss: это файл генерируется на на основе стилей
 * найденных в шаблонах Rails. Запускается как отдельный сервис @unocss/cli.
 * Так же добавлены настройки сканирования в `ui-uno.config.ts`,
 * что из этого работает правильно, неясно. Вопрос требует проработки и
 * изучения.
 */
import './styles/rails.css'

import 'vue3-side-panel/dist/vue3-side-panel.css'

// const app = createApp(App)
// app.use(VueSidePanel)

// @see https://vuejs.org/guide/extras/web-components#building-custom-elements-with-vue
// import CustomFeedback from './components/FeedbackForm.ce.vue'
// const CustomFeedbackElement = defineCustomElement(CustomFeedback)
// customElements.define('form-example', CustomFeedbackElement)

function mountApp () {
  try {
    createApp(App)
      .use(router)
      .use(VueSidePanel)
      .use(Oruga, bulmaConfig)
      .mount('#app')
  } catch (e) {
    console.log('#app: wrong')
  }
}

function mountTypesense () {
  console.log('mount')

  var _element = document.getElementById('typesense-index')

  if (!_element) return;

  console.log('ready')

  try {
    createApp(TypesenseView)
      .use(vuetify)
      .use(Oruga, bulmaConfig)
      .use(InstantSearch)
      .mount(_element)
  } catch (e) {
    console.warn(e)
  }
}

function mountFeedback () {
  try {
    createApp(FeedbackForm)
      .use(Oruga, bulmaConfig)
      .mount('#feedback')
  } catch (e) {
    console.log('#feedback: wrong')
  }
}

if (document.querySelector('#app')) {
  console.info('find:#app')
  mountApp()
} else {
  console.warn('no element #app')
}

if (document.querySelector('#feedback')) {
  console.info('find:#feedback')
  mountFeedback()
} else {
  console.warn('no element #feedback')
}

mountTypesense()
